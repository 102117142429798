import React from "react";


const StepOnePrivate = (props) => {

    return (

<div>
    <h1 className="title">Uzņēmuma līguma apstiprināšana solis 1. no 2 - uzņēmuma dati</h1>



    <form onSubmit={props.handleFinishStep1}  className={'box'}>


        <div className="field">
            <label className="label">Personas vārds un uzvārds</label>
            <div className="control">
                <input     defaultValue={props.partner.company_name}  required onChange={(e) => props.setPrivateName(e.target.value)}   className="input" type="text"/>
            </div>
        </div>


        <div className="field">
            <label className="label">Adrese</label>
            <div className="control">
                <input required onChange={(e) => props.setPrivateAddress(e.target.value)}  className="input" type="text"/>
            </div>
        </div>

        <div className="field">
            <label className="label">Personas kods</label>
            <div className="control">
                <input required onChange={(e) => props.setPersonCode(e.target.value)}  className="input" type="text"/>
            </div>
        </div>

        <div className="field is-grouped">
            <div className="control">
                <button disabled={props.submitDisabledStep1} type="submit" className="button is-link">Tālāk
                </button>
            </div>
        </div>

    </form>
</div>
    )

};

export default StepOnePrivate;


/*

defaultValue={props.partner.company_name}


    <div className={'notification is-warning'}>Aizpildiet šo formu, lai pabeigtu reģistrāciju un uzsāktu darbu!</div>

          <div className="field">
                <label className="label">Personas kods</label>
                <div className="control">
                    <input className="input" type="text"/>
                </div>
            </div>
 */