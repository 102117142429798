import React from "react";


const PrivateContract = (props) => {

    return (
        <div className={'box'}>
            <h3><span size={2}>UZŅĒMUMA LĪGUMS</span></h3>
            <h3>Nr. <span className={'tag'}>{props.contractNumber}</span></h3>
            <p><br /></p>
            <table width={614} cellSpacing={0} cellPadding={7}>
                <tbody>
                <tr>
                    <td width="50%">
                        <p>Rīgā,</p>
                    </td>
                    <td width="50%">
                        <p><span className={'tag'}>{props.contractSignDate}</span></p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p><br /></p>
            <p><span size={2}><strong>WASH SIA</strong></span><span size={2}>, vienotais reģistrācijas Nr. 40203289586, juridiskā adrese: Vangažu iela 5, Rīga, LV-1024, kuru uz statūtu pārstāv tās valdes loceklis Ģirts Vilciņš, turpmāk tekstā saukts&nbsp;</span><span size={2}><strong>Pasūtītājs</strong></span><span size={2}>, no vienas puses, un <span className={'tag'}>{props.privateName}</span>, personas kods <span className={'tag'}>{props.personCode}</span>, dzīvo: <span className={'tag'}>{props.privateAddress}</span>,</span><span size={2}>&nbsp;</span><span size={2}>turpmāk tekstā saukts&nbsp;</span><span size={2}><strong>Izpildītājs,&nbsp;</strong></span><span size={2}>no otras puses, abi kopā turpmāk&nbsp;</span><span size={2}>tekstā saukti&nbsp;</span><span size={2}><strong>Puses,&nbsp;</strong></span><span size={2}>pamatojoties uz Civillikuma 2212.-2229. pantu&nbsp;</span>normām, noslēdz sekojoša satura līgumu:</p>
            <ol>
                <li>
                    <p><strong>LĪGUMA PRIEKŠMETS</strong></p>
                </li>
            </ol>
            <p><span size={2}><strong>Pasūtītājs&nbsp;</strong></span><span size={2}>uzdod&nbsp;</span><span size={2}><strong>Izpildītājam&nbsp;</strong></span><span size={2}>un&nbsp;</span><span size={2}><strong>Izpildītājs&nbsp;</strong></span><span size={2}>apņemas par saviem spēkiem un līdzekļiem veikt&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>sniegto pakalpojumu reklāmas izplatīšanas pakalpojumus, piedāvājot&nbsp;</span><span size={2}><strong>Pasūtītāja</strong></span> izstrādāto individuālo atlaižu kodu fiziskām un juridiskām personām, saskaņā ar šī Līguma noteikumiem un nosacījumiem;</p>
            <p><br /></p>
            <p><strong>1.IZPILDĪTĀJA TIESĪBAS UN PIENĀKUMI</strong></p>
            <ol>
                <ol>
                    <li>
                        <p><span size={2}><strong>Izpildītāja&nbsp;</strong></span><span size={2}>pienākums ir uzdevumu veikt, saskaņā ar šī līguma&nbsp;</span>priekšmetu kvalitatīvi un termiņā, kādu nosaka pušu vienošanās.</p>
                    </li>
                    <li>
                        <p><span size={2}>Pēc&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>pieprasījuma&nbsp;</span><span size={2}><strong>Izpildītājs&nbsp;</strong></span><span size={2}>informē&nbsp;</span><span size={2}><strong>Pasūtītāju&nbsp;</strong></span><span size={2}>par&nbsp;</span><span size={2}>uzdotā uzdevuma izpildes gaitu un atbild uz&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span>interesējošiem jautājumiem saistībā ar uzdoto uzdevumu.</p>
                    </li>
                    <li>
                        <p><span size={2}><strong>Izpildītājs&nbsp;</strong></span><span size={2}>apņemas saskaņot savu darbību ar&nbsp;</span><span size={2}><strong>Pasūtītāju&nbsp;</strong></span><span size={2}>rīcībā,&nbsp;</span><span size={2}>kura skar&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>intereses. Šī punkta izpratnē&nbsp;</span><span size={2}><strong>Pasūtītāju&nbsp;</strong></span>pārstāv Pasūtītāja darbinieks vai tā pilnvarots pārstāvis.</p>
                    </li>
                    <li>
                        <p><span size={2}><strong>Izpildītājs&nbsp;</strong></span><span size={2}>apņemas izpildīt&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>doto uzdevumu saskaņā&nbsp;</span>ar spēkā esošo normatīvo aktu prasībām.</p>
                    </li>
                    <li>
                        <p><span size={2}><strong>Izpildītājs&nbsp;</strong></span><span size={2}>apņemas neizpaust trešajām personām&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>konfidenciālu informāciju, kas tam kļuvusi zināma pildot&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>doto&nbsp;</span>uzdevumu.</p>
                    </li>
                    <li>
                        <p><span size={2}><strong>Izpildītājam&nbsp;</strong></span><span size={2}>ir&nbsp;</span><span size={2}>tiesības saņemt visu uzdevuma veikšanai nepieciešamo informāciju un&nbsp;</span><span size={2}>materiālus, kas nepieciešami&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>dotā uzdevuma izpildei</span>.</p>
                    </li>
                </ol>
            </ol>
            <p><br /></p>
            <ol start={2}>
                <li>
                    <p><strong>PASŪTĪTĀJA TIESĪBAS UN PIENĀKUMI</strong></p>
                    <ol>
                        <li>
                            <p><span size={2}><strong>Pasūtītājs&nbsp;</strong></span><span size={2}>apņemas sniegt&nbsp;</span><span size={2}><strong>Izpildītājam&nbsp;</strong></span><span size={2}>visus dokumentus un&nbsp;</span>ziņas, kas nepieciešamas dotā uzdevuma izpildei.</p>
                        </li>
                        <li>
                            <p><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>sniegtajai informācijai ir jābūt patiesai un pēc satura&nbsp;</span><span size={2}>un apjoma ir jāatbilst&nbsp;</span><span size={2}><strong>Izpildītāja&nbsp;</strong></span><span size={2}>prasībām. Informācijas atbilstības<br />&nbsp;</span><span size={2}>kritērijus nosaka&nbsp;</span><span size={2}><strong>Izpildītājs. Izpildītājs&nbsp;</strong></span><span size={2}>nav atbildīgs par sekām, kas&nbsp;</span>radušās nepatiesas informācijas sniegšanas rezultātā.</p>
                        </li>
                        <li>
                            <p><span size={2}>Nepieciešamības gadījumā&nbsp;</span><span size={2}><strong>Pasūtītājam&nbsp;</strong></span><span size={2}>ir pienākums rakstiski pilnvarot&nbsp;</span><span size={2}><strong>Izpildītāju&nbsp;</strong></span>veikt uzdoto uzdevumu.</p>
                        </li>
                        <li>
                            <p><span size={2}><strong>Pasūtītājs&nbsp;</strong></span><span size={2}>apņemas uzskatīt visus jautājumus, kas tiek risināti&nbsp;</span>starp pusēm tikai par sev saistošiem un neizpaust trešajām personām.</p>
                        </li>
                        <li>
                            <p><span size={2}><strong>Pasūtītājs&nbsp;</strong></span><span size={2}>apņemas samaksāt&nbsp;</span><span size={2}><strong>Izpildītājam&nbsp;</strong></span><span size={2}>atlīdzību&nbsp;</span><span size={2}>5% (pieci procenti) apmērā no kopējās piesaistīto klientu izmantotās atlaižu koda samaksātās summas, kuru Pasūtītājs ir saņēmis no Izpildītāja tam piesaistītajiem klientiem 1 (vienu) reizi mēnesi, saskaņā ar Pasūtītājam iesniegto un savstarpēji starp Pusēm saskaņoto darbu nodošanas – pieņemšanas aktu par iepriekšējā mēnesī faktiski izpildīto darbu apjomu.</span></p>
                        </li>
                        <li>
                            <p><span size={2}><strong>Pasūtītājam</strong></span><span size={2}>&nbsp;rodas pienākums maksāt atlīdzības maksu&nbsp;</span><span size={2}><strong>Izpildītājam</strong></span><span size={2}>&nbsp;ar brīdi, kad&nbsp;</span><span size={2}><strong>Pasūtītājs</strong></span><span size={2}>&nbsp;ir saņēmis līdzekļus no&nbsp;</span><span size={2}><strong>Izpildītāja</strong></span><span size={2}>&nbsp;piesaistītā klienta, tam izmantojot piešķirto atlaides kodu.&nbsp;</span></p>
                        </li>
                        <li>
                            <p><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;1(vienu) vienu reizi mēnesi, saņemot informāciju no&nbsp;</span><span size={2}><strong>Pasūtītāja</strong></span><span size={2}>&nbsp;par piesaistītājiem klientiem par iepriekšējo mēnesi, ne vēlāk kā līdz katra tekošā mēneša 10-tajam datumam, un saskaņā ar&nbsp;</span><span size={2}><strong>Pasūtītāja</strong></span><span size={2}>&nbsp;sniegto informāciju, ka&nbsp;</span><span size={2}><strong>Pasūtītājs</strong></span><span size={2}>&nbsp;ir saņēmis naudas līdzekļus no&nbsp;</span><span size={2}><strong>Izpildītāja</strong></span><span size={2}>&nbsp;piesaistītā klienta, sagatavo un nosūta&nbsp;</span><span size={2}><strong>Pasūtītājam</strong></span><span size={2}>&nbsp;Pakalpojumu pieņemšanas – nodošanas aktu, kas apliecina, ka attiecīgie pakalpojumi ir sniegti.&nbsp;</span><span size={2}><strong>Pasūtītājs</strong></span><span size={2}>&nbsp;izskata Pakalpojumu pieņemšanas – nodošanas aktu 3 (trīs) darba dienu laikā no tā saņemšanas dienas un apstiprina to vai nosūta&nbsp;</span><span size={2}><strong>Izpildītājam</strong></span><span size={2}>&nbsp;pamatotus iebildumus. Ja&nbsp;</span><span size={2}><strong>Pasūtītājs</strong></span><span size={2}>&nbsp;augstāk norādītajā termiņā neparaksta Pakalpojumu pieņemšanas – nodošanas aktu, bet arī neiesniedz rakstiskus iebildumus, tiek atzīts, ka&nbsp;</span><span size={2}><strong>Pasūtītājs</strong></span><span size={2}>&nbsp;ir pieņēmusi Izpildītāja sniegtos pakalpojumus un ir apstiprinājusi Pakalpojumu pieņemšanas – nodošanas aktu.</span></p>
                        </li>
                    </ol>
                </li>
            </ol>
            <p><br /></p>
            <ol>
                <ol start={8}>
                    <li>
                        <p><span size={2}>Veicot&nbsp;</span><span size={2}><strong>Izpildītājam</strong></span><span size={2}>&nbsp;samaksu par sniegtajiem pakalpojumiem,&nbsp;</span><span size={2}><strong>Pasūtītājs&nbsp;</strong></span><span size={2}>no 2.5.punktā noteiktās atlīdzības ietur visus normatīvajos aktos noteiktos nodokļus. Gadījumā, ja šī līguma darbības laikā&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;reģistrēsies Valsts ieņēmumu dienestā kā saimnieciskās darbības veicējs,&nbsp;</span><span size={2}><strong>Izpildītājs&nbsp;</strong></span><span size={2}>nekavējoties iesniegs</span><span size={2}><strong>Pasūtītājam</strong></span> reģistrācijas apliecības kopiju un turpmāk patstāvīgi veiks visu normatīvajos aktos noteikto nodokļu nomaksu.&nbsp;</p>
                    </li>
                    <li>
                        <p><span size={2}><strong>Pasūtītājs&nbsp;</strong></span><span size={2}>ir tiesīgs saņemt informāciju par&nbsp;</span><span size={2}><strong>Izpildītāja&nbsp;</strong></span><span size={2}>uzdotā uzdevuma izpildes gaitu un&nbsp;</span><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>interesējošiem jautājumiem&nbsp;</span>saistībā ar uzdoto uzdevumu.</p>
                    </li>
                </ol>
            </ol>
            <p><br /></p>
            <ol start={3}>
                <li>
                    <p><strong>RĒĶINU KĀRTĪBA</strong></p>
                    <ol>
                        <li>
                            <p><span size={2}><strong>Pasūtītāja&nbsp;</strong></span><span size={2}>norēķini ar&nbsp;</span><span size={2}><strong>Izpildītāju&nbsp;</strong></span><span size={2}>tiek veikti ar pārskaitījumu 1(vienu) reizi mēnesī 3(trīs) darba dienu laikā uz&nbsp;</span><span size={2}><strong>Izpildītāja&nbsp;</strong></span><span size={2}>Līgumā</span>norādīto norēķinu kontu, saskaņā ar darbu pieņemšanas – nodošanas aktu.</p>
                        </li>
                        <li>
                            <p><span size={2}><strong>Izpildītājs</strong></span><span size={2}>, pēc savstarpējas Pušu vienošanās ir tiesīgs Līguma 2.5. punktā minēto atlīdzību un/vai kādu tās daļu saņemt&nbsp;</span><span size={2}><strong>Pasūtītāja</strong></span> sniegto pakalpojumu izmantošanai 1 (viena) gada laikā no šāda pieprasījuma veikšanas brīža. Šajā punktā minētajā gadījumā starp Pusēm tiek parakstīts savstarpējs ieskaita akts.</p>
                        </li>
                        <li>
                            <p><span size={2}>Ja&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;noteiktajā termiņā nav izmantojis 3.2.minēto&nbsp;</span><span size={2}><strong>Pasūtītāja</strong></span><span size={2}>&nbsp;pakalpojumu,&nbsp;</span><span size={2}><strong>Izpildītājam</strong></span><span size={2}>&nbsp;zūd tiesības par minēto atlīdzības daļu prasīt zaudējumu atlīdzību no&nbsp;</span><span size={2}><strong>Pasūtītāja</strong></span>.&nbsp;</p>
                        </li>
                    </ol>
                </li>
            </ol>
            <p><br /></p>
            <ol start={4}>
                <li>
                    <p><span size={2}><strong>LĪGUMA STĀŠANĀS SPĒKĀ,</strong></span><strong>&nbsp;TĀ GROZĪŠANAS,</strong></p>
                </li>
            </ol>
            <p><strong>PAPILDINĀŠANAS UN LAUŠANAS KĀRTĪBA</strong></p>
            <ol>
                <ol>
                    <li>
                        <p><span size={2}>Šis līgums stājas spēkā tā parakstīšanas brīdī un ir spēkā līdz <span className={'tag'}>{props.contractSignDate}</span></span></p>
                    </li>
                    <li>
                        <p><span size={2}>Līgums var tikt grozīts, papildināts, vai lauzts tikai pēc pušu<br />&nbsp;</span><span size={2}>savstarpējas vienošanās rakstiskā veidā. Līguma laušanas gadījumā otrā&nbsp;</span>puse ir jābrīdina vienu mēnesi iepriekš.</p>
                    </li>
                </ol>
            </ol>
            <p><br /></p>
            <p><span size={2}><strong>5.</strong></span><span size={2}><strong>&nbsp;KONFIDENCIALITĀTE</strong></span></p>
            <p><br /></p>
            <table width={657} cellSpacing={0} cellPadding={7}>
                <tbody>
                <tr>
                    <td colSpan={2} width="96.97933227344993%">
                        <p><span size={2}>5.1.&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;piekrīt, ka tam bez termiņa ierobežojuma ir jāievēro šādi konfidencialitātes un informācijas neatklāšanas nosacījumi:</span></p>
                        <p><span size={2}>5.1.1.&nbsp;</span><span size={2}><strong>Izpildītājam</strong></span><span size={2}>&nbsp;jāatzīst kā stingri konfidenciāla visa informācija, dati, dokumenti un materiāli, jebkurā formā, kas ir saņemti, iegādāti, izveidoti vai iesniegti saistībā ar šo Līgumu, izņemot tos, kas ir publiski zināmi un pieejami;</span></p>
                        <p><span size={2}>5.1.2.&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;apņemas neatklāt, nepublicēt, neizplatīt un neizmantot par labu kādai trešajai personai jebkuru konfidenciālu informāciju, datus, dokumentus un materiālus, jebkurā formā, kas ir saņemti, iegādāti, izveidoti vai iesniegti saistībā ar šo Līgumu, izņemot, ja ir saņemta&nbsp;</span><span size={2}><strong>Pasūtītāja</strong></span><span size={2}>&nbsp;iepriekšēja rakstveida piekrišana vai šāda informācija ir pieprasīta saskaņā ar normatīvi tiesiskajiem aktiem;</span></p>
                        <p><span size={2}>5.1.3.&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;apņemas neizmantot konfidenciālu informāciju, datus, dokumentus un materiālus, lai gūtu peļņu; un</span></p>
                        <p><span size={2}>5.1.4.&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;apņemas ievērot noteikumu, ka konfidenciāla informācija, dati, dokumenti un materiāli var tikt atklāti un izmantoti no&nbsp;</span><span size={2}><strong>Izpildītāja</strong></span><span size={2}>&nbsp;puses tikai tādos gadījumos, kad tas ir nepieciešams&nbsp;</span><span size={2}><strong>Izpildītāja</strong></span><span size={2}>&nbsp;pakalpojumu izpildei, ievērojot ierobežojumus, kurus noteic&nbsp;</span><span size={2}><strong>Pasūtītājs</strong></span><span size={2}>.</span></p>
                        <p><span size={2}>5.2.&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;apņemas nodrošināt šādu saistību izpildi:</span></p>
                        <p><span size={2}>5.2.1. Jebkuri dokumenti, dati, informācija vai materiāli, jebkurā formā, kas ir saņemti, iegādāti, izveidoti vai iesniegti, izpildot šo Līgumu, tiks attiecīgi glabāti, lai nodrošinātu šādu dokumentu, datu, informācijas vai materiālu drošību.</span></p>
                        <p><span size={2}>5.3.&nbsp;</span><span size={2}><strong>Izpildītājs</strong></span><span size={2}>&nbsp;apņemas pildīt augstāk norādītās saistības Līguma darbības laikā un arī pēc tā izbeigšanās.&nbsp;</span></p>
                        <p><br /></p>
                    </td>
                    <td width="3.0206677265500796%">
                        <p><br /></p>
                    </td>
                </tr>
                <tr>
                    <td width="3.0206677265500796%" height={288} />
                    <td colSpan={2} width="96.97933227344993%">
                        <ol start={6}>
                            <li>
                                <p><span size={2}><strong>CITI NOSACĪJUMI</strong></span></p>
                            </li>
                        </ol>
                        <p><br /></p>
                        <ol start={6}>
                            <ol>
                                <li>
                                    <p><span size={2}>Jebkurš rakstisks paziņojums saskaņā ar šo Līgumu iesniedzams personīgi vai nosūtāms pa pastu uz attiecīgās puses adresi, kas norādīta Līgumā vai par kuru attiecīgā puse rakstiski informējusi otru pusi. Šādi paziņojumi uzskatāmi par saņemtiem, kad tie ir faktiski nogādāti (ja ir nogādāti personīgi) vai septītajā dienā pēc tam, kad ir nosūtīti pa pastu.</span></p>
                                </li>
                                <li>
                                    <p><span size={2}>Katra puse apņemas informēt otru pusi par savas adreses vai citu rekvizītu izmaiņām ne vēlāk kā 3 (trīs) darba dienu laikā no brīža, kad attiecīgās izmaiņas stājas spēkā.</span></p>
                                </li>
                                <li>
                                    <p><span size={2}>Jebkuri šā Līguma grozījumi ir spēkā, ja tie ir izpildīti rakstveidā un ir abu pušu parakstīti.</span></p>
                                </li>
                                <li>
                                    <p><span size={2}>Nodaļu un apakšnodaļu numerācija, kas izmantota šajā Līgumā, ir izmantota tikai ērtības nolūkos un nekādā gadījumā nevar tikt interpretēta, lai skaidrotu, ierobežotu, tulkotu vai aprakstītu šādu nodaļu/apakšnodaļu apjomu vai būtību, vai kādā citā veidā ietekmētu šo Līgumu.</span></p>
                                </li>
                                <li>
                                    <p><span size={2}>Šis Līgums atspoguļo pilnīgu Pušu vienošanos par tajā iekļautajiem noteikumiem un nosacījumiem, un attiecībā uz šī Līguma priekšmetu nepastāv nekādas citas mutiskas vai rakstiskas tiešas vai netiešas vienošanās, solījumi, noteikumi, nosacījumi vai apņemšanās, kas nav iekļauti Līgumā.</span></p>
                                </li>
                                <li>
                                    <p><span size={2}>Ar šo Līgumu tiek izbeigtas jebkuras iepriekšējās vienošanās, mutiskās un rakstiskās, kas tika panāktas starp Pusēm un kas attiecas uz šī Līguma priekšmetu.</span></p>
                                </li>
                                <li>
                                    <p><span size={2}>Puses ar saviem parakstiem apliecina, ka ir pilnībā iepazinušās ar šī Līguma noteikumiem un nosacījumiem, un pilnībā apzinās savas saistības un pienākumus.&nbsp;</span></p>
                                </li>
                                <li>
                                    <p><span size={2}>Līgums ir sagatavots un parakstīts 2 (divos) eksemplāros, kuriem ir vienāds juridisks spēks. Viens eksemplārs ir paredzēts&nbsp;</span><span size={2}><strong>Izpildītājam</strong></span><span size={2}>, viens –&nbsp;</span><span size={2}><strong>Pasūtītājam</strong></span><span size={2}>.&nbsp;</span></p>
                                </li>
                            </ol>
                        </ol>
                    </td>
                </tr>
                </tbody>
            </table>
            <p><br /></p>


        </div>
    )

};

export default PrivateContract;

/*
         <ol start={7}>
                <li>
                    <p><strong>PUŠU ADRESES UN REKVIZĪTI</strong></p>
                </li>
            </ol>
            <dl>
                <dd>
                    <table width={540} cellSpacing={0} cellPadding={7}>
                        <tbody>
                        <tr>
                            <td width="51.171875%">
                                <p><strong>Izpildītājs:</strong></p>
                                <p><br /></p>
                            </td>
                            <td width="48.828125%">
                                <p><span size={2}><strong>Pasūtītājs:</strong></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td width="51.171875%">
                                <p><span size={2}>____________</span></p>
                            </td>
                            <td width="48.828125%">
                                <p><span size={2}><strong>WASH SIA</strong></span></p>
                            </td>
                        </tr>
                        <tr>
                            <td width="51.171875%">
                                <p><span size={2}>Personas kods: ____________</span></p>
                            </td>
                            <td width="48.828125%">
                                <p><span size={2}>Vienotās reģ. Nr. 40203289586</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td width="51.171875%">
                                <p><span size={2}>Adrese___________________</span></p>
                            </td>
                            <td width="48.828125%">
                                <p><span size={2}>Vangažu iela 5, Rīga, LV-1024</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td width="51.171875%">
                                <p><span size={2}>Banka____________________</span></p>
                            </td>
                            <td width="48.828125%">
                                <p><span size={2}>Tālrunis: 25760755</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td width="51.171875%">
                                <p><span size={2}>Konta Nr. ________________</span></p>
                                <p><br /></p>
                                <p><br /></p>
                                <p><br /></p>
                            </td>
                            <td width="48.828125%">
                                <p><span size={2}>Konts Nr. LV95PARX0023797580001</span></p>
                                <p><br /></p>
                                <p><br /></p>
                                <p><span size={2}>Ģirts Vilciņš, valdes loceklis</span></p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </dd>
            </dl>
            <p><br /></p>
 */