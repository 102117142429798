import React from "react";


const StepOneLegal = (props) => {

    return (
<div>
    <h1 className="title">Starpniecības pakalpojuma līguma apstiprināšana solis 1 no 2 - uzņēmuma dati</h1>

    <form onSubmit={props.handleFinishStep1}   className={'box'}>
        <div className="field">
            <label className="label">Uzņēmuma nosaukums</label>
            <div className="control">
                <input required defaultValue={props.partner.company_name}  onChange={(e) => props.setCompanyName(e.target.value)}  className="input" type="text"/>
            </div>
        </div>

        <div className="field">
            <label className="label">Uzņēmuma reģistrācijas numurs</label>
            <div className="control">
                <input required onChange={(e) => props.setRegNumber(e.target.value)}  className="input" type="text"/>
            </div>
        </div>

        <div className="field">
            <label className="label">Juridiskā adrese</label>
            <div className="control">
                <input required onChange={(e) => props.setLegalAddress(e.target.value)} className="input" type="text"/>
            </div>
        </div>

        <div className="field">
            <label className="label">Valdes loceklis</label>
            <div className="control">
                <input required onChange={(e) => props.setContactPerson(e.target.value)} className="input" type="text"/>
            </div>
        </div>


        <button disabled={props.submitDisabledStep1}  className="button is-info">Tālāk</button>

    </form>
</div>
    )

};

export default StepOneLegal;


/*

  <div className={'notification is-warning'}>Aizpildiet šo formu, lai pabeigtu reģistrāciju!</div>

            <div className="field">
                <label className="label">Kontaktpersonas vārds un uzvārds</label>
                <div className="control">
                    <input className="input" type="text"/>
                </div>
            </div>
 */