import * as React from "react"
import {navigate} from "gatsby"
import {useEffect, useState} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {isLoggedIn} from "../utils/auth.service";
import authHeader from "../utils/auth-header";
import StepOnePrivate from "../components/agreement/step1-private";
import StepOneLegal from "../components/agreement/step1-legal";
import StepTwoPrivate from "../components/agreement/step2-private";
import StepTwoLegal from "../components/agreement/step2-legal";
import toast from "react-hot-toast";
import LoadingSpinner from "../components/loading-spinner";

const Agreement = () => {

   const [loading, setLoading] = useState(true);
    const [partner, setPartner] = useState([]);
    const [isStepOneCompleted, setIsStepOneCompleted] = useState(false);
    const [isStepTwoCompleted, setIsStepTwoCompleted] = useState(false);
    const [submitDisabledStep1, setSubmitDisabledStep1] = useState(false);
    const [submitDisabledStep2, setSubmitDisabledStep2] = useState(false);

    const [privateName, setPrivateName] = useState('');
    const [privateAddress, setPrivateAddress] = useState('');
    const [personCode, setPersonCode] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [reNumber, setRegNumber] = useState('');
    const [legalAddress, setLegalAddress] = useState('');
    const [contactPerson, setContactPerson] = useState('');

    const [contractNumber, setContractNumber] = useState('');
    const [contractSignDate, setContractSignDate] = useState('');




        useEffect(() => {

        if (!isLoggedIn()) {
            return navigate('/');
        }

        if(isStepTwoCompleted){
            return navigate('/dashboard');
        }

        loadDashboardData();

    }, [])


    const loadDashboardData = () => {


        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };



        fetch(process.env.WASHCAR_API_URL + "/partners/agreement", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {

                setPartner(resultData.partner);

                if(resultData.partner.registration_completed_at !== null){
                    setIsStepOneCompleted(true);
                }

                if(resultData.partner.contract_signed_at !== null){
                    setIsStepTwoCompleted(true);
                    return navigate('/dashboard');

                }

                if(resultData.contract_data !== null){
                    if(resultData.partner.business_type === 'individual'){
                        setPrivateName(resultData.contract_data.name);
                        setPrivateAddress(resultData.contract_data.address);
                        setPersonCode(resultData.contract_data.person_code);

                    }
                    else{
                        setCompanyName(resultData.contract_data.company_name);
                        setRegNumber(resultData.contract_data.reg_number);
                        setLegalAddress(resultData.contract_data.legal_address);
                        setContactPerson(resultData.contract_data.contact_person_name);
                    }
                }
                else{

                    setPrivateName(resultData.partner.company_name);
                    setCompanyName(resultData.partner.company_name);
                }

                setContractNumber(resultData.contract_number);
                setContractSignDate(resultData.contract_sign_date);


                setLoading(false);

            }) //
    }

    const getRequestData = () => {


        if(partner.business_type === 'individual'){
         return {
             name: privateName,
             address: privateAddress,
             person_code: personCode,
         };
        }
        else{
            return {
              company_name: companyName,
                reg_number: reNumber,
                legal_address: legalAddress,
                contact_person_name: contactPerson,
            }
        }

    }

    const handleFinishStep1 = (e) => {


        e.preventDefault();
        setLoading(true)


        try {

            setSubmitDisabledStep1(true);

            const requestData = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(getRequestData())
            }


            fetch(process.env.WASHCAR_API_URL + "/partners/post-complete-registration",requestData)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    setSubmitDisabledStep1(false);
                    setLoading(false)
                    resultData.status === 'Error' ?  toast.error(resultData.alert.text) : toast.success(resultData.alert.text);

                    setIsStepOneCompleted(true);
                    //if OK show step 2

                }) // set data for the number of stars




        } catch (err) {
            console.log(err);
        }


    };

    const handleFinishStep2 = (e) => {


        e.preventDefault();
        setLoading(true)

        try {

            setSubmitDisabledStep1(true);
            setLoading(true);


            const requestOptions = {
                method: 'POST',
                headers: authHeader(),

            };

            fetch(process.env.WASHCAR_API_URL + "/partners/post-complete-contract", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    setSubmitDisabledStep2(false);
                    setLoading(false)
                    resultData.status === 'Error' ?  toast.error(resultData.alert.text) : toast.success(resultData.alert.text);

                    setIsStepTwoCompleted(true);
                    return navigate('/dashboard');
                    //if OK show step 2


                }) // set data for the number of stars




        } catch (err) {
            console.log(err);
        }


    };


    return (
        <Layout>

            <Seo title="Līguma apstiprināšana"/>

            {loading ? <div className="pos-login"><LoadingSpinner /></div> : null}

            {isStepOneCompleted === true ?    partner.business_type === 'individual'  ?
                <StepTwoPrivate contractSignDate={contractSignDate} contractNumber={contractNumber} personCode={personCode} privateName={privateName} privateAddress={privateAddress}  submitDisabledStep2={submitDisabledStep2} handleFinishStep2={handleFinishStep2} partner={partner} /> :
                <StepTwoLegal contractSignDate={contractSignDate} contractNumber={contractNumber} contactPerson={contactPerson}  legalAddress={legalAddress} companyName={companyName}  reNumber={reNumber} handleFinishStep2={handleFinishStep2} submitDisabledStep2={submitDisabledStep2} partner={partner} />  :   partner.business_type === 'individual'  ?
                <StepOnePrivate setPersonCode={setPersonCode} setPrivateName={setPrivateName} setPrivateAddress={setPrivateAddress} submitDisabledStep1={submitDisabledStep1} setSubmitDisabledStep1={setSubmitDisabledStep1} handleFinishStep1={handleFinishStep1} partner={partner} /> :
                <StepOneLegal  setContactPerson={setContactPerson} setCompanyName={setCompanyName}     submitDisabledStep1={submitDisabledStep1} setSubmitDisabledStep1={setSubmitDisabledStep1}  setRegNumber={setRegNumber} setLegalAddress={setLegalAddress} handleFinishStep1={handleFinishStep1} partner={partner} /> }
        </Layout>
    )
}

export default Agreement

