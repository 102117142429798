import React from "react";
import PrivateContract from "../contracts/private-contract";

const StepTwoPrivate= (props) => {

    return (
<div>
    <h1 className="title">Uzņēmuma līguma apstiprināšana solis 2. no 2 - apstiprinājums</h1>
    <div className={'notification is-warning'}>Atzīmējiet, ka piekrītiet līguma nosacījumiem!</div>

    <PrivateContract contractSignDate={props.contractSignDate} contractNumber={props.contractNumber} personCode={props.personCode} privateName={props.privateName} privateAddress={props.privateAddress} />

<form onSubmit={props.handleFinishStep2}  className={'box'}>
    <label className="checkbox">
        <input required="required" type="checkbox"/>
        Es piekrītu līguma nosacījumiem
    </label>

    <div className="field is-grouped mt-2">
        <div className="control">
            <button disabled={props.submitDisabledStep2} type="submit" className="button is-link">Pabeigt reģistrāciju
            </button>
        </div>
    </div>

</form>

</div>
    )

};

export default StepTwoPrivate;