import React from "react";
import LegalContract from "../contracts/legal-contract";

const StepTwoLegal = (props) => {

    return (
<div>
    <h1 className="title">Starpniecības pakalpojuma līguma apstiprināšana solis 1 no 2 - apstiprinājums</h1>

    <div className={'notification is-warning'}>Atzīmējiet, ka piekrītiet līguma nosacījumiem!</div>

    <LegalContract contractSignDate={props.contractSignDate} contractNumber={props.contractNumber} contactPerson={props.contactPerson}  partner={props.partner} companyName={props.companyName} regNumber={props.reNumber} legalAddress={props.legalAddress}  />

    <form onSubmit={props.handleFinishStep2}  className={'box mt-2'}>
        <label className="checkbox">
            <input required="required" type="checkbox"/>
           Piekrītu līguma nosacījumiem
        </label>

        <div className="field is-grouped mt-2">
            <div className="control">
                <button disabled={props.submitDisabledStep2} type="submit" className="button is-link">Iesniegt
                </button>
            </div>
        </div>

    </form>
</div>
    )

};

export default StepTwoLegal;