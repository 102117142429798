import React from "react";


const LegalContract = (props) => {

    return (
   <div className={'box'}>
       <table width={624} cellSpacing={0} cellPadding={7}>
           <tbody>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>STARPNIECĪBAS PAKALPOJUMU LĪGUMS Nr. <span className={'tag'}>{props.contractNumber}</span></strong></p>
                   <p><br /></p>
                   <p><br /></p>
               </td>
           </tr>

           <tr>
               <td width="100%" valign="top">
                   <p><span size={2}>Šis Starpniecības pakalpojumu līgums (turpmāk – “</span><span size={2}><strong>Līgums</strong></span>”) ir noslēgts 2022.gada  <span className={'tag'}>{props.contractSignDate}</span>starp:</p>
                   <p><br /></p>
                   <p><span size={2}><strong>SIA “WASH”</strong></span><span size={2}>, kompāniju, kas reģistrēta un darbojas saskaņā ar Latvijas Republikas normatīvajiem aktiem, reģistrācijas Nr. 40203289586, juridiskā adrese: Vangažu iela 5, Rīga, LV-1024 (turpmāk – “</span><span size={2}><strong>Sabiedrība</strong></span>”), tās valdes locekļa Ģirta Vilciņa personā, no vienas puses,</p>
                   <p>un</p>
                   <p><span size={2}><strong className={'tag'}>{props.companyName}</strong></span><span size={2}>, vienotais reģistrācijas Nr. <span className={'tag'}>{props.regNumber}</span>, juridiskā adrese: <span className={'tag'}>{props.legalAddress}</span>, (turpmāk – „</span><span size={2}><strong>Starpnieks</strong></span>”) kuru uz statūtu pārstāv tās valdes loceklis <span className={'tag'}>{props.contactPerson}</span>, no otras puses,</p>
                   <p><br /></p>
                   <p><br /></p>
                   <p><span size={2}>Sabiedrība un Starpnieks turpmāk kopā saukti „</span><span size={2}><strong>Puses</strong></span><span size={2}>”, bet katrs atsevišķi – „</span><span size={2}><strong>Puse</strong></span>”,</p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><br /></p>
                   <p>ŅEMOT VĒRĀ, ka Sabiedrība vēlas nolīgt Starpnieku, lai pēdējais sniegtu reklāmas starpniecības pakalpojumus saskaņā ar Līguma noteikumiem un nosacījumiem;</p>
                   <p><br /></p>
                   <p>ŅEMOT VĒRĀ, ka Starpnieks ir uzskatāms par tādu, kurš spēj sniegt specifiskus pakalpojumus, kas ir nepieciešami Sabiedrībai;</p>
                   <p><br /></p>
                   <p>ŅEMOT VĒRĀ, ka Starpnieks pieņem Sabiedrības piedāvājumu,</p>
                   <p><br /></p>
                   <p>TĀDĒĻ, ņemot vērā pušu savstarpējo vienošanos un saskaņā ar turpmāk izklāstītiem noteikumiem un nosacījumiem, Puses noslēdz šo Līgumu par sekojošo:</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>1. Līguma priekšmets</strong></p>
                   <p>1.1. Saskaņā ar Līguma noteikumiem un nosacījumiem Starpnieks apņemas sniegt, bet Sabiedrība apņemas pieņemt un apmaksāt Starpnieka pakalpojumus, kas saistīti ar reklāmas sniegšanu un izvietošanu Starpniekam pieejamajās vietās un resursos par Sabiedrības piedāvātajiem pakalpojumiem, Starpniekam izsniedzot un vai kā citādi informējot savus klientus, atkarībā no tehniskiem palīglīdzekļiem, kurus Starpnieks izmanto savā saimnieciskās darbībās veikšanā nodrošinot pakalpojumu saviem klientiem, piešķirot Sabiedrības individuālo atlaižu kodu.</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p>1.2. Sniedzot Līguma 1.1.punktā paredzētos reklāmas starpnieka pakalpojumus, Starpnieks apņemas:</p>
                   <p>1.2.1. piesaistīt potenciālos klientus, kuri būtu gatavi izmantot Sabiedrības pakalpojumus;</p>
                   <p>1.2.2. piedāvāt Sabiedrības piešķirto individuālo atlaižu kodu Sabiedrības pakalpojuma saņemšanai;</p>
                   <p>1.2.3. sniegt potenciālajiem klientiem visu informāciju par Sabiedrību un pakalpojuma saņemšanas noteikumiem;</p>
                   <p>1.2.4. veikt citas nepieciešamās darbības, lai veicinātu klientu piesaisti Sabiedrībai.</p>
                   <p><br /></p>
                   <p>1.3. Starpniekam pēc paša ieskatiem jāizvēlas darbības, paņēmieni un metodes veiksmīgai augstāk minētā starpniecības uzdevuma izpildei pie nosacījuma, ka Starpnieks tam uzticētos pienākumus veic patstāvīgi.&nbsp;</p>
                   <p><br /></p>
                   <p>1.4. Starpniekam jāizmanto paša finanšu, tehniskie un citi resursi šajā Līgumā noteikto pakalpojumu sniegšanai.</p>
                   <p><br /></p>
                   <p>1.5. Starpniekam nav tiesību pieņemt lēmumus par potenciālo klientu apstiprināšanu vai noraidīšanu.&nbsp;</p>
                   <p><br /></p>
                   <p>1.6. Starpnieks nav atbildīgs par informācijas, kas saņemta no Sabiedrības saturu un ticamību. Starpnieks nav atbildīgs par saistību neizpildi no piesaistītā klienta puses.</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>2. Starpnieka juridiskais statuss</strong></p>
                   <p>2.1. Starpniekam ir neatkarīgs juridiskais statuss un nekas, kas iekļauts šajā Līgumā vai ir saistīts ar to, nevar tikt interpretēts tādējādi, ka starp Pusēm ir izveidota apvienība vai personālsabiedrība, vai tādējādi, ka ir izveidota citāda pārstāvība, izņemot Starpniekam deleģētos reklāmas starpnieka pienākumus un pienākošos komisiju saskaņā ar šo Līgumu.</p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>3. Sabiedrības apliecinājumi</strong></p>
                   <p>3.1. Sabiedrība apliecina, ka:</p>
                   <p>3.1.1. Tā ir pienācīgi organizēta, likumīgi darbojas saskaņā ar Latvijas Republikas normatīvajiem aktiem, neatrodas likvidācijas, reorganizācijas vai maksātnespējas procesā, un tā ir tiesīga veikt komercdarbību, kā tā tiek veikta šobrīd;</p>
                   <p>3.1.2. Sabiedrībai ir pilnīgas tiesības un pilnvaras noslēgt šo Līgumu, kā arī jebkuru citu līgumu, vienošanos un dokumentu, kas noslēdzams no Sabiedrības puses saskaņā ar šo Līgumu, un ir pilnīgas tiesības un pilnvaras uzņemties ar Līgumu paredzētās un ar to saistītās saistības. Sabiedrībai nav nepieciešama nevienas citas personas atteikšanās vai piekrišana, lai noslēgtu un izpildītu šo Līgumu, kā arī jebkuru citu līgumu, vienošanos un dokumentu, kas noslēdzams no Sabiedrības puses saskaņā ar šo Līgumu;</p>
                   <p>3.1.3. Sabiedrībai nav zināmas nekādas prasības vai strīdi, kuros būtu iesaistīta Sabiedrība saistībā ar tās nodokļu saistību izpildi, kurus rakstveidā ierosinājusi kāda valsts vai pašvaldības iestāde;</p>
                   <p>3.1.4. Pret Sabiedrību nav uzsākti tiesas procesi, administratīvās lietvedības, arbitrāžas vai citādi procesi; Sabiedrība neatrodas un tai nedraud valsts vai pašvaldības iestāžu izmeklēšanas, kas saistītas ar pašu Sabiedrību, tās īpašumiem vai komercdarbību. Sabiedrība nav noslēgusi un uz to neattiecas neviens mierizlīgums, priekšraksts, pavēle vai cits administratīvs dokuments saistībā ar kādu īpašumu, kas pieder Sabiedrībai, vai kuru tā īrē/nomā vai lieto uz cita pamata. Sabiedrība nav saņēmusi informācijas pieprasījumu, paziņojumu, pavēli, administratīvu rīkojumu, vai oficiālu/neoficiālu sūdzību vai prasību saistībā ar kādu īpašumu, kas pieder Sabiedrībai vai kuru tā īrē/nomā vai lieto uz cita pamata, vai saistībā ar Sabiedrības pamatlīdzekļiem vai Sabiedrības darbībām;</p>
                   <p>3.1.5. Sabiedrība nepārkāpj likumus, noteikumus un citus normatīvos aktus, kas attiecas uz Sabiedrības komercdarbību vai Sabiedrības pamatlīdzekļiem vai īpašumiem, kas pieder Sabiedrībai vai kurus tā īrē/nomā vai lieto uz cita pamata;</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>4. Sabiedrības pienākumi</strong></p>
                   <p>4.1. Sabiedrība, ņemot vērā informāciju, kas ir saņemta no Starpnieka, apņemas informēt pēdējo par tās piekrišanu vai atteikumu veikt Pakalpojuma Izpildi no Starpnieka atrastā klienta.&nbsp;</p>
                   <p><br /></p>
                   <p>4.2. Sabiedrība apņemas nodrošināt Starpnieku ar visu informāciju, kas attiecas uz Sabiedrību un reklāmas koda piesaisti, un kas ir nepieciešama Starpnieka pakalpojumu izpildei saskaņā ar šo Līgumu.&nbsp;</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>5. Starpnieka komisija un norēķinu noteikumi</strong></p>
                   <p><br /></p>
                   <p>5.1. Starpnieks par viņa sniegtajiem pakalpojumiem ir tiesīgs saņemt komisijas maksu 5% (pieci procenti) apmērā no kopējās piesaistīto klientu izmantotās atlaižu koda samaksātās summas, kuru Sabiedrība saņēma no Starpnieka piesaistītajiem klientiem 1 (vienu) reizi mēnesi. Šajā punktā minētā komisijas maksa tiek maksāta 5 (piecu) darba dienu laikā no attiecīgā Pakalpojumu pieņemšanas – nodošanas akta parakstīšanas dienas, turpmāk tekstā – “Komisijas maksa”. PVN tiek piemērots, saskaņā ar spēkā esošajiem normatīvajiem aktiem.</p>
                   <p><br /></p>
                   <p>5.2. Sabiedrībai rodas pienākums maksāt Starpnieka Komisijas maksu ar brīdi, kad Sabiedrība ir saņēmusi līdzekļus no Starpnieka piesaistītā klienta, tam izmantojot piešķirto atlaides kodu.&nbsp;</p>
                   <p><br /></p>
                   <p>5.3. Starpnieks, 1(vienu) vienu reizi mēnesi, saņemot informāciju no Sabiedrības par piesaistītājiem klientiem par iepriekšējo mēnesi, ne vēlāk kā līdz katra tekošā mēneša 10-tajam datumam, un saskaņā ar Sabiedrības sniegto informāciju, ka Sabiedrība ir saņēmusi naudas līdzekļus no Starpnieka piesaistītā klienta, sagatavo un nosūta Sabiedrībai Pakalpojumu pieņemšanas – nodošanas aktu, kas apliecina, ka attiecīgie pakalpojumi ir sniegti. Sabiedrība izskata Pakalpojumu pieņemšanas – nodošanas aktu 3 (trīs) darba dienu laikā no tā saņemšanas dienas un apstiprina to vai nosūta Starpniekam pamatotus iebildumus. Ja Sabiedrība augstāk norādītajā termiņā neparaksta Pakalpojumu pieņemšanas – nodošanas aktu, bet arī neiesniedz rakstiskus iebildumus, tiek atzīts, ka Sabiedrība ir pieņēmusi Starpnieka sniegtos pakalpojumus un ir apstiprinājusi Pakalpojumu pieņemšanas – nodošanas aktu.</p>
                   <p><br /></p>
                   <p>5.4. Sabiedrība apņemas maksāt Starpnieka Komisijas maksu ar pārskaitījumu uz Starpnieka norādīto norēķinu kontu 5 (piecu) darba dienu laikā no attiecīgā rēķina saņemšanas dienas. Komisija maksājama EUR valūtā.</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>6. Līguma termiņš</strong></p>
                   <p>6.1. Līgums stājas spēkā dienā, kad to ir parakstījušas abas Puses.</p>
                   <p><br /></p>
                   <p>6.2. Līgums ir spēkā <span className={'tag'}>{props.contractSignDate}</span> un turpmāk paliek spēkā kamēr Starpnieka Komisijas maksa tiek samaksāta pilnā apmērā.</p>
                   <p><br /></p>
                   <p>6.3. Ja Līguma termiņš tiek pagarināts, pagarinājuma laikā tiek piemēroti šā Līguma noteikumi un nosacījumi.&nbsp;</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>7. Strīdu izskatīšana</strong></p>
                   <p>7.1. Pušu tiesiskās attiecības regulē un tās ir interpretējamas saskaņā ar Latvijas Republikas normatīvi tiesiskajiem aktiem.</p>
                   <p><br /></p>
                   <p>7.2. Jebkurš strīds, nesaskaņa vai prasība, kas izriet no Līguma, kas skar to vai tā pārkāpšanu, izbeigšanu vai spēkā neesamību, tiks izšķirts Latvijas Republikas tiesā pēc piekritības.</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>8. Konfidencialitāte</strong></p>
                   <p>8.1. Starpnieks piekrīt, ka tam bez termiņa ierobežojuma ir jāievēro šādi konfidencialitātes un informācijas neatklāšanas nosacījumi:</p>
                   <p>8.1.1. Starpniekam jāatzīst kā stingri konfidenciāla visa informācija, dati, dokumenti un materiāli, jebkurā formā, kas ir saņemti, iegādāti, izveidoti vai iesniegti saistībā ar šo Līgumu, izņemot tos, kas ir publiski zināmi un pieejami;</p>
                   <p>8.1.2. Starpnieks apņemas neatklāt, nepublicēt, neizplatīt un neizmantot par labu kādai trešajai personai jebkuru konfidenciālu informāciju, datus, dokumentus un materiālus, jebkurā formā, kas ir saņemti, iegādāti, izveidoti vai iesniegti saistībā ar šo Līgumu, izņemot, ja ir saņemta Sabiedrības iepriekšēja rakstveida piekrišana vai šāda informācija ir pieprasīta saskaņā ar normatīvi tiesiskajiem aktiem;</p>
                   <p>8.1.3. Starpnieks apņemas neizmantot konfidenciālu informāciju, datus, dokumentus un materiālus, lai gūtu peļņu; un</p>
                   <p>8.1.4. Starpnieks apņemas ievērot noteikumu, ka konfidenciāla informācija, dati, dokumenti un materiāli var tikt atklāti un izmantoti no Starpnieka puses tikai tādos gadījumos, kad tas ir nepieciešams Starpnieka pakalpojumu izpildei, ievērojot ierobežojumus, kurus noteic Sabiedrība.</p>
                   <p><br /></p>
                   <p>8.2. Starpnieks apņemas nodrošināt šādu saistību izpildi:</p>
                   <p>8.2.1. Jebkuri dokumenti, dati, informācija vai materiāli, jebkurā formā, kas ir saņemti, iegādāti, izveidoti vai iesniegti, izpildot šo Līgumu, tiks attiecīgi glabāti, lai nodrošinātu šādu dokumentu, datu, informācijas vai materiālu drošību.</p>
                   <p><br /></p>
                   <p>8.3. Starpnieks apņemas pildīt augstāk norādītās saistības Līguma darbības laikā un arī pēc tā izbeigšanās.&nbsp;</p>
                   <p><br /></p>
               </td>
           </tr>
           <tr>
               <td width="100%" valign="top">
                   <p><strong>9. Citi nosacījumi</strong></p>
                   <p>9.1. Jebkurš rakstisks paziņojums saskaņā ar šo Līgumu iesniedzams personīgi vai nosūtāms pa pastu uz attiecīgās puses adresi, kas norādīta Līgumā vai par kuru attiecīgā puse rakstiski informējusi otru pusi. Šādi paziņojumi uzskatāmi par saņemtiem, kad tie ir faktiski nogādāti (ja ir nogādāti personīgi) vai septītajā dienā pēc tam, kad ir nosūtīti pa pastu.</p>
                   <p><br /></p>
                   <p>9.2. Katra puse apņemas informēt otru pusi par savas adreses vai citu rekvizītu izmaiņām ne vēlāk kā 3 (trīs) darba dienu laikā no brīža, kad attiecīgās izmaiņas stājas spēkā.</p>
                   <p><br /></p>
                   <p>9.3. Jebkuri šā Līguma grozījumi ir spēkā, ja tie ir izpildīti rakstveidā un ir abu pušu parakstīti.</p>
                   <p><br /></p>
                   <p>9.4. Nodaļu un apakšnodaļu numerācija, kas izmantota šajā Līgumā, ir izmantota tikai ērtības nolūkos un nekādā gadījumā nevar tikt interpretēta, lai skaidrotu, ierobežotu, tulkotu vai aprakstītu šādu nodaļu/apakšnodaļu apjomu vai būtību, vai kādā citā veidā ietekmētu šo Līgumu.</p>
                   <p><br /></p>
                   <p>9.5. Šis Līgums atspoguļo pilnīgu Pušu vienošanos par tajā iekļautajiem noteikumiem un nosacījumiem, un attiecībā uz šī Līguma priekšmetu nepastāv nekādas citas mutiskas vai rakstiskas tiešas vai netiešas vienošanās, solījumi, noteikumi, nosacījumi vai apņemšanās, kas nav iekļauti Līgumā.</p>
                   <p><br /></p>
                   <p>9.6. Ar šo Līgumu tiek izbeigtas jebkuras iepriekšējās vienošanās, mutiskās un rakstiskās, kas tika panāktas starp Pusēm un kas attiecas uz šī Līguma priekšmetu.</p>
                   <p><br /></p>
                   <p>9.7. Puses ar saviem parakstiem apliecina, ka ir pilnībā iepazinušās ar šī Līguma noteikumiem un nosacījumiem, un pilnībā apzinās savas saistības un pienākumus.&nbsp;</p>
                   <p><br /></p>
                   <p>9.8. Līgums ir sagatavots un parakstīts 2 (divos) eksemplāros, kuriem ir vienāds juridisks spēks. Viens eksemplārs ir paredzēts Sabiedrībai, viens – Starpniekam.&nbsp;</p>
               </td>
           </tr>
           </tbody>
       </table>
   </div>
    )

};

export default LegalContract;